import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import useFetchData from './useFetchData';

export default function ServicesList() {
    const {data, loading, error} = useFetchData('services');
    const [services, setServices] = useState([]);

    useEffect(() => {
        if (data) setServices(data)
    }, [data]);

    return (
        <ul className="cards">
            {services.map(service => <li key={service.id} className="card"><h3 className="card-title">{service.title.rendered}</h3></li>)}
            <li className="card">
                <Link className="btn btn-white" to="/services">Learn more <i className="btn-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m15 5-1.41 1.41L18.17 11H2v2h16.17l-4.59 4.59L15 19l7-7-7-7z"/></svg></i></Link>
            </li>
        </ul>
    )
}
