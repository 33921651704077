import React from "react";
import { Link } from "react-router-dom";

export default function About() {
    return (
        <>
            <section className="bio text-center">
                <div className="bio-section bg-primary">
                    <div className="bio-inner">
                        <p className="bio-info">It's simple—</p>
                        <h2 className="bio-title text-uppercase">We're <span>problem</span> <span>solvers</span></h2>
                    </div>
                    <div className="bio-arrow"></div>
                </div>
                <div className="bio-section">
                    <div className="bio-inner">
                        <h2 className="bio-subtitle text-uppercase mb-3">and we're here to help.</h2>
                        <p className="bio-info">Whether it is your business or your home, we understand that <span className="color-primary"><strong>technology</strong></span> is an important asset and has become indispensable in your life as well as your business. With the right <span className="color-primary"><strong>tools</strong></span> in your toolbox, anything is possible.</p>
                        <p className="bio-info"><strong>Let us help you find the right <span className="color-primary">solution</span>.</strong></p>
                    </div>
                </div>
            </section>

            <section className="wrapper">
                <div className="container">
                    <h2 className="color-primary mb-2">Our mission:</h2>
                    <blockquote className="quote em bg-darkest color-secondary">
                        <span className="quote-icon bg-primary"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5 3.871 3.871 0 0 1-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5 3.871 3.871 0 0 1-2.748-1.179z"/></svg></span>
                        Empower the world by simplifying technology solutions through teaching and guidance.
                    </blockquote>
                </div>
            </section>

            <section className="wrapper">
                <div className="container">
                    <h2 className="color-primary mb-2">Our passion:</h2>
                    <ul className="fancy-list">
                        <li>Technology with a creative approach</li>
                        <li>Understanding your concerns</li>
                        <li>Helping you solve challenges</li>
                        <li>Return on your investments</li>
                        <li>Applying the right tools and solutions to meet your goals</li>
                    </ul>
                </div>
            </section>

            <section className="wrapper">
                <div className="container">
                    <Link className="btn" to="/services">See the services we provide →</Link>
                </div>
            </section>
        </>
    )
}