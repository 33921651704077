import React from "react";
import PartnersList from "./PartnersList";
import ServicesList from "./ServicesList";

export default function Home() {
    return (
        <>
            <section className="jumbotron">
                <div className="jumbotron-bg" style={{backgroundImage: `url("./images/bg-particles.jpg")`}}>
                    <video className="jumbotron-video"
                           autoPlay={true}
                           loop={true}
                           muted={true}
                           preload="auto"
                           poster={`./images/bg-particles.jpg)`}
                           playsInline>
                        <source src="./videos/particles.mp4" type="video/mp4" /> 
                    </video>
                </div>
                <div className="jumbotron-vignette"></div>
                <div className="jumbotron-body">
                    <div className="container">
                        <h2 className="jumbotron-title title-underline text-uppercase">Let us manage the tech. You focus on goals.</h2>
                    </div>
                </div>
            </section>

            <section className="wrapper bg-primary color-white">
                <div className="container">
                    <div className="mb-2">
                        <h2>Our Services</h2>
                    </div>
                    
                    <ServicesList />
                </div>
			</section>

			<section className="billboard billboard--fancy billboard-lg" style={{backgroundImage: `url(./images/office.jpg)`}}>
                <div className="wrapper">
                    <div className="container">
                        <div className="billboard-vignette"></div>
                        <div className="billboard-body">
                            <h2 className="color-primary">Hi, lets chat!</h2>
                            <p>Call us for a free consultation to learn about how we can help you manage your technology today.</p>
                            <span className="mt-3"><a className="btn" href="tel:4707905725">*Call now <i className="btn-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15 12h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3zm4 0h2a9 9 0 0 0-9-9v2c3.87 0 7 3.13 7 7zm1 3.5c-1.25 0-2.45-.2-3.57-.57-.1-.03-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2a15.045 15.045 0 0 1-6.59-6.59l2.2-2.21a.96.96 0 0 0 .25-1A11.36 11.36 0 0 1 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM5.03 5h1.5c.07.88.22 1.75.45 2.58l-1.2 1.21c-.4-1.21-.66-2.47-.75-3.79zM19 18.97c-1.32-.09-2.6-.35-3.8-.76l1.2-1.2c.85.24 1.72.39 2.6.45v1.51z"/></svg></i></a></span>
			                <p className="mt-1">*By clicking this button and providing a telephone number via voicecall or SMS text message, you consent to be contacted by phone or SMS text message. Message & data rates may apply. You can opt out of further messaging at any time.</p>
                        </div>
                    </div>
                </div>
			</section>

			<section className="wrapper">
				<div className="container">
                    <h2 className="color-primary">Our Partners</h2>
					<p>Technology partners that we team up with to bring you the latest products and services.</p>

					<PartnersList />
				</div>
            </section>
        </>
    )
}
