import React from 'react'

export default function Policy() {
	return (
		<>
			<section className="wrapper">
				<div className="container text-center">
					<h2 className="h1">Privacy Policy</h2>
					<p>Effective Date: June 1, 2024</p>
				</div>
			</section>
			

			<section className="wrapper">
				<div className="container">
					<h3><strong>Introduction</strong></h3>
					<p>A Tech Solution ("we," "us," or "our"). We run https://atechsolution.co/ and offer various products and services. This Privacy Policy outlines how we 
		collect, use, and share your personal information when you use our IT services.</p>

					<h3><strong>Our Commitment to Privacy</strong></h3>
					<p>We are dedicated to protecting your privacy and will not use or share your personal information except as described in this Privacy Policy.</p>

					<h3><strong>Your Participation</strong></h3>
					<p>Please read this Privacy Policy thoroughly to understand how we protect your information and how you can help keep it safe. </p>
		
					<h3><strong>Information We Collect</strong></h3>
					<p>We collect and use personal information only for the purposes we've explained unless we have the person's consent or it's required by law. We'll keep this information only as long as needed for those reasons. We collect personal information legally and fairly, and, when necessary, with the individual's knowledge or consent.
					We may collect personal information that you provide to us when you:</p>
					<ul className="list-disc">
						<li>Contact us for IT services</li>
						<li>Request a quote or consultation</li>
						<li>Communicate with us through email, phone, or other channels</li>
						<li>Participate in surveys or promotions</li>						
					</ul>

					<p>The types of personal information we may collect include:</p>
					<ul className="list-disc">
						<li>Name</li>
						<li>Address</li>
						<li>Email</li>
						<li>Phone number</li>
						<li>Payment information</li>
						<li>Any other information you choose to provide</li>
					</ul>
					
					<h3><strong>How We Use Your Information</strong></h3>
					<p>We use the information we collect to:</p>
					<ul className="list-disc">
						<li>Provide and manage our IT services</li>
						<li>Communicate with you about your account or services</li>
						<li>Respond to your inquiries and requests</li>
						<li>Process your payments and transactions</li>						
						<li>Improve our services and customer experience</li>
						<li>Send you marketing and promotional materials (if you have opted-in)</li>
					</ul>
					
					<h3><strong>Information Sharing and Disclosure</strong></h3>
					<p>We do not share, sell your personal information, or give your consent to third parties, affiliates, or partners. We may share your information with:</p>
					<ul className="list-disc">
						<li>Service providers and contractors who perform services on our behalf</li>
						<li>Law enforcement or governing agencies if required by law</li>
						<li>Other parties with your consent</li>
					</ul>

					<h3><strong>Data Security</strong></h3>
					<p>We take steps to keep your personal information safe from unauthorized access, use, or sharing. However, no Internet or electronic storage method is 
					completely secure and we cannot guarantee absolute security.</p>
						
					<h3><strong>Manage Your Preferences and Personal Information</strong></h3>
					<p>You may opt out of SMS messaging, marketing, and promotional messages from us by following the instructions in those messages or contacting us. You can also ask to access, update, or delete your personal information by contacting us directly. See SMS Messaging below for more details. 
					</p>

					<h3><strong>SMS Messaging and communications</strong></h3>
					<p>We are committed to protecting your privacy and ensuring that your personal information is handled securely and responsibly.</p>
					
					<ul className="list-disc">
						<li><strong>Consent Gathering:</strong></li>
						<p>We obtain permission through our website to send SMS messages.</p>
						<li><strong>Opt-In Confirmation:</strong></li>
						<p>By entering your phone number and agreeing to receive SMS messages, you authorize us to use your information as outlined in this Privacy Policy. Upon opting in, we'll send you an SMS confirmation.</p>
						<li><strong>Withdrawal of Consent: </strong></li>
						<p>You may withdraw your consent to receive SMS messages at any time by following the instructions provided in our messages or contacting us directly.
						You can stop receiving SMS messages at any time by following the instructions in our messages or contacting us directly. To cancel the service, text 'STOP' to 470.790.5725. We'll send a confirmation message once you send 'STOP', and you'll no longer receive messages from us. 
						If you want to subscribe again, simply text 'START'.</p>
					</ul>
					<p>When you sign up to use this service, it will be used to:</p>
					<ul className="list-disc">
						<li>Communicate with you</li>
						<li>Provide customer support and respond to your inquiries and requests</li>
						<li>Send you important updates, promotions, and other information related to our services</li>
					</ul>

					<p>If you need assistance, simply text 'HELP' to 470.790.5725. We'll promptly respond with instructions on using our service and how to unsubscribe.</p>
					<p>Please note that carriers are not responsible for messages that are delayed or not delivered.</p>
					<p>Standard message and data rates may apply for messages sent to or from us. If you have any questions about your text or data plan, please contact your wireless provider.</p>

					<h3><strong>Changes to This Privacy Policy</strong></h3>
					<p>This Privacy Policy is effective from June 1, 2024. It will continue to apply unless we make changes in the future, which will take effect immediately when posted on this page.
					We may update or change this Privacy Policy at any time, so please check back periodically. If we make significant changes, we will notify you via email or by clearly posting a notice on our website.
					If we update this Privacy Policy, your continued use of the Service means you agree to the changes.</p>

					<h3><strong>Contact Us</strong></h3>
					<p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:</p>
					<p>A Tech Solution <br />
					3276 Buford Dr.<br />
					Suite 104 #390<br />
					Buford, GA 30519<br />
					<a href="mailto:legal@atechsolution.co">legal@atechsolution.co</a><br />
					470.790.5725</p>
				</div>
			</section>
		</>
	)
}
