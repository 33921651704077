import React from 'react'

export default function Opps() {
	return (
		<section className="wrapper">
			<div className="container text-center">
				<h2 className="h1">Opps! That page does not exist...</h2>
			</div>
		</section>
	)
}
