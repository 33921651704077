import React, {useState, useLayoutEffect, useEffect, useRef} from 'react';
import {NavLink} from 'react-router-dom';

export default function Header(props) {
    const {name, page} = props;

    const [scrollThreshold, setScrollThreshold] = useState(false);
    const [currentScrollY, setCurrentScrollY] = useState(0);
    const [lastKnownScrollY, setLastKnownScrollY] = useState(0);
    const [headerEngaged, setHeaderEngaged] = useState(false);
    const [headerActive, setHeaderActive] = useState(false);
    const [navOpen, setNavOpen] = useState(false);

	const headerRef = useRef(null);

	useLayoutEffect(() => {
		if (headerRef.current) {
			setScrollThreshold(headerRef.current.offsetHeight);
		};
	}, [headerRef]);

	useEffect(() => {
		// First lets get the current scrollY position
		window.addEventListener('scroll', () => {
			setCurrentScrollY(window.scrollY);
		});

		// Check if scrollY is bigger than header height
		if (currentScrollY > scrollThreshold) {
			setHeaderEngaged(true);
		} else {
			setHeaderEngaged(false);
		}

		if (headerEngaged && currentScrollY < lastKnownScrollY) {
			setHeaderActive(true);
		} else {
			setHeaderActive(false);
		}

		setTimeout(() => {
			// Lastly we set the latest known scrollY position
			if (currentScrollY !== lastKnownScrollY) setLastKnownScrollY(currentScrollY)
		}, 0)
	}, [currentScrollY]);

    useEffect(() => {
        setNavOpen(false);
    }, [page]);

    useEffect(() => {
        if (navOpen) {
            document.querySelector('html').classList.add('nav-is-active');
        } else {
            document.querySelector('html').classList.remove('nav-is-active');
        }
    }, [navOpen]);

    return (
        <>
            <header id="header" className={`app-header ${headerEngaged ? 'header-is-engaged' : ''} ${headerActive ? 'header-is-active' : ''}`}
					ref={headerRef}>
                <div className="app-header-inner">
                    <NavLink className="app-logo color-primary" to="/" onClick={() => setNavOpen(false)}>
                        <span className="app-logo-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 382"><path fillRule="evenodd" clipRule="evenodd" d="M279.919 90.557c6.843 4.933 6.673 15.249-.194 20.147l-122.636 87.703c-4.309 3.073-10.143 3.001-14.428-.106l-36.212-25.875c-6.773-4.914-6.782-15.007-.017-19.932l54.146-37.7c1.132-.824 2.132-1.828 2.706-3.106 1.832-4.082 4.468-14.202-8.313-23.492-13.559-9.857-28.285-7.042-33.088-5.731-1.086.296-2.073.831-2.983 1.493L58.226 127.91a12.322 12.322 0 01-14.474.009l-24.086-17.238c-6.763-4.904-6.72-15.011.018-19.949L142.646 2.795c4.306-3.156 10.17-3.165 14.501-.043l122.772 87.805zm-94.011-3.627c15.686 0 28.403-9.357 28.403-20.9 0-11.542-12.717-20.9-28.403-20.9-15.687 0-28.404 9.358-28.404 20.9 0 11.543 12.717 20.9 28.404 20.9z" /><path d="M20.49 125.9c-8.146-5.905-19.64-.159-19.639 9.902v31.187c0 3.944 1.888 7.649 5.078 9.966l34.276 24.522c3.19 2.318 5.079 6.38 5.079 10.324v103.966a12.32 12.32 0 004.87 9.812l24.86 17.804c8.145 5.904 19.55.085 19.55-9.975v-87.092c0-2.517 2.854-3.971 4.89-2.492l24.833 17.785c8.144 5.917 19.561.099 19.561-9.967v-31.189a12.32 12.32 0 00-5.088-9.974L20.49 125.9zM279.845 125.551c8.145-5.905 19.496-.186 19.496 9.875v31.058c0 3.944-1.747 7.818-4.937 10.136l-86.614 61.94a6.09 6.09 0 00-2.511 4.929c0 4.978 5.574 7.857 9.601 4.931l64.899-46.39c8.144-5.917 19.562-.1 19.562 9.967v71.757c0 3.948-1.899 7.643-5.095 9.961L175.254 378.81c-8.145 5.905-18.915.766-18.915-9.294v-32.032c0-3.943 1.978-7.549 5.168-9.867l86.022-61.516c1.577-1.146 2.533-2.957 2.533-4.907 0-4.975-5.644-7.851-9.669-4.926l-65.3 46.675c-8.144 5.917-19.086.49-19.086-9.576v-72.909a12.32 12.32 0 015.088-9.974l118.75-84.933z" /></svg></span>
                        <h1 className="app-title app-logo-slogan" dangerouslySetInnerHTML={{__html: name.replace(/ /g, '&nbsp;')}} />
                    </NavLink>
                    <nav className="nav">
                        <NavLink className="nav-logo color-primary" to="/" onClick={() => setNavOpen(false)}>
                            <span className="nav-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 382"><path fillRule="evenodd" clipRule="evenodd" d="M279.919 90.557c6.843 4.933 6.673 15.249-.194 20.147l-122.636 87.703c-4.309 3.073-10.143 3.001-14.428-.106l-36.212-25.875c-6.773-4.914-6.782-15.007-.017-19.932l54.146-37.7c1.132-.824 2.132-1.828 2.706-3.106 1.832-4.082 4.468-14.202-8.313-23.492-13.559-9.857-28.285-7.042-33.088-5.731-1.086.296-2.073.831-2.983 1.493L58.226 127.91a12.322 12.322 0 01-14.474.009l-24.086-17.238c-6.763-4.904-6.72-15.011.018-19.949L142.646 2.795c4.306-3.156 10.17-3.165 14.501-.043l122.772 87.805zm-94.011-3.627c15.686 0 28.403-9.357 28.403-20.9 0-11.542-12.717-20.9-28.403-20.9-15.687 0-28.404 9.358-28.404 20.9 0 11.543 12.717 20.9 28.404 20.9z" /><path d="M20.49 125.9c-8.146-5.905-19.64-.159-19.639 9.902v31.187c0 3.944 1.888 7.649 5.078 9.966l34.276 24.522c3.19 2.318 5.079 6.38 5.079 10.324v103.966a12.32 12.32 0 004.87 9.812l24.86 17.804c8.145 5.904 19.55.085 19.55-9.975v-87.092c0-2.517 2.854-3.971 4.89-2.492l24.833 17.785c8.144 5.917 19.561.099 19.561-9.967v-31.189a12.32 12.32 0 00-5.088-9.974L20.49 125.9zM279.845 125.551c8.145-5.905 19.496-.186 19.496 9.875v31.058c0 3.944-1.747 7.818-4.937 10.136l-86.614 61.94a6.09 6.09 0 00-2.511 4.929c0 4.978 5.574 7.857 9.601 4.931l64.899-46.39c8.144-5.917 19.562-.1 19.562 9.967v71.757c0 3.948-1.899 7.643-5.095 9.961L175.254 378.81c-8.145 5.905-18.915.766-18.915-9.294v-32.032c0-3.943 1.978-7.549 5.168-9.867l86.022-61.516c1.577-1.146 2.533-2.957 2.533-4.907 0-4.975-5.644-7.851-9.669-4.926l-65.3 46.675c-8.144 5.917-19.086.49-19.086-9.576v-72.909a12.32 12.32 0 015.088-9.974l118.75-84.933z" /></svg></span>
                            <span className="nav-slogan">{name}</span>
                        </NavLink>
                        <ul className="nav-list mb-0">
                            <li className="nav-item">
                                <NavLink
									className={`nav-link ${isActive => isActive ? 'nav-link-is-active' : ''}`}
									onClick={() => setNavOpen(false)} to="/services"
								>Services</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={`nav-link ${page === '/posts' || page.indexOf('posts') > -1 ? 'nav-link-is-active' : ''}`} onClick={() => setNavOpen(false)} to="/posts">News&nbsp;&&nbsp;Articles</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={`nav-link ${page === '/about' ? 'nav-link-is-active' : ''}`} onClick={() => setNavOpen(false)} to="/about">About</NavLink>
                            </li>
							<li className="nav-item">
                                <button className="nav-link nav-link-cta btn btn-small" onClick={() => {
                                    setNavOpen(false);
                                    setTimeout(() => {
                                        document.querySelector('#footer').scrollIntoView({behavior: 'smooth'});
                                    }, 0)
                                }} to="/about">Contact Us</button>
                            </li>
                        </ul>
                    </nav>
                    <button className="menu" onClick={() => setNavOpen(navOpen ? false : true)}>
                        <span className="menu-bar"></span>
                        <span className="menu-bar"></span>
                        <span className="menu-bar"></span>
                    </button>
                </div>

				<div className="faux-header"></div>
            </header>
        </>
    )
}
