import { useState, useEffect } from "react";

export default function useFetchData(type, param) {
	const ROOT = 'https://atechsolco.wpengine.com/wp-json/wp/v2/';
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchDataType = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${ROOT}${type}/${param && param.length > 0 ? param : ''}`);
                const json = await response.json();
                
				if (response.status === 404) throw 'Error loading page'

                setData(json);
            } catch(err) {
                setError(true);
				console.log(err)
            } finally {
                setLoading(false);
            }
        }

        fetchDataType();
    }, [type])

    return {data, loading, error}
}
