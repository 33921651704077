import React, { useEffect, useState } from "react";
import Article from './Article';
import useFetchData from './useFetchData';

export default function Posts() {
    const {data, loading, error} = useFetchData('posts?_embed');
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (data && data.length > 0) {
            setArticles(data);
        }
    }, [data]);

    return (
        <>
            <section className="wrapper">
                <div className="container">
                    <h2 className="h1 text-uppercase">Industry News & Articles</h2>
                    <p>Keep up with the latest industry news and posts.</p>
                </div>
            </section>

            <section className="wrapper">
                <div className="container">

                    {articles.map((article) => (
                        <Article
                            key={article.id}
							id={article.id}
                            isExternal={article.acf.is_external_post}
                            publisher={article.acf.is_external_post ? article.acf.publisher : article.x_author}
                            domain={article.acf.is_external_post ? article.acf.publisher_domain : undefined}
                            url={article.acf.is_external_post ? article.acf.article_link : article.slug}
                            imageUrl={article.x_featured_media_medium && article.x_featured_media_medium}
                            title={article.title.rendered}
                            postDate={article.acf.is_external_post ? article.acf.publish_date: article.date}
                            excerpt={article.excerpt.rendered}
                            slug={!article.acf.is_external_post && article.slug}
                        />
                    ))}

                </div>
            </section>
        </>
    )
}