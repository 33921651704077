import React, { useState, useEffect } from 'react';
import useFetchData from './useFetchData';

export default function PartnersList(props) {
    const {data, loading, error} = useFetchData('partners', '?per_page=12&_embed=true');
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        if (data) {
            const newList = [];
            const randomList = [];
            
            data.map(partner => {
                let item = {id: null, title: null, image: null};

                item.id = partner.id;
                item.title = partner.title.rendered;
                // item.image = partner.x_featured_media_medium ? partner.x_featured_media_medium : undefined;
                item.image = partner._embedded['wp:featuredmedia'][0].source_url;

                newList.push(item)
            });

            do {
                const randomIndex = Math.floor(Math.random() * newList.length);
                
                if (!randomList.includes(newList[randomIndex])) {
                    randomList.push(newList[randomIndex]);
                }
            }
            while (randomList.length < newList.length)

            setPartners(partners => [...partners, randomList][0]);
        }
    }, [data]);

    return (
        <ul className="partner-list">
            {partners.map(partner => {
				if (partner.image) {
					return <li key={partner.id}><img className="partner-img" src={partner.image} alt={`${partner.title} logo`} /></li>
				}
            })}
        </ul>
    )
}
