import React, {useEffect, useState} from "react";
import useFetchData from './useFetchData';

export default function Services() {
    const {data, loading, error} = useFetchData('services?_embed=true');
    const [services, setServices] = useState([]);

    useEffect(() => {
        if (data) {
            const newList = [];

            // Lets just get what we need
            data.map(service => {
                console.log(service)

                let item = {id: null, title: null, info: null, image: null};
				let hasImg = service.x_featured_media_original ? true : false;

                item.id = service.id;
                item.title = service.title.rendered;
                item.info = service.content.rendered;
                item.info = item.info.split('<p>')[1].split('</p>')[0];
				item.hasImg = hasImg;
				
				if (hasImg) item.image = service.x_featured_media_original;

                newList.push(item);
            });

            newList.reverse();
			
            setServices(services => [...services, newList][0]);
        }
    }, [data]);

    return (
        <>
            <section className="wrapper">
                <div className="container">
                    <h2 className="h1 text-uppercase">Services</h2>
                    <p>See below for all the services we have to offer. <a className="link" href="tel:4707905725">*Contact us</a> for a free 30 minute discussion to see what we can do for you today.</p>
                    <small className="mt-1">*By clicking this button and providing a telephone number via voicecall or SMS text message, you consent to be contacted by phone or SMS text message. Message & data rates may apply. You can opt out of further messaging at any time.</small>
                </div>
            </section>

            <ul className="mb-0 zebra-stripe">
                {services.map(service => {
                    return (
                        <li key={service.id}
							id=""
							className="billboard billboard--fancy billboard-md wrapper"
							>
                            <div className="container">
                                {/* <div className="billboard-vignette"></div> */}
                                <div className="billboard-body">
                                    <h3 className="h2">{service.title}</h3>
                                    <p dangerouslySetInnerHTML={{__html: service.info}} />
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}