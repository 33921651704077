import React, {useState, useEffect, useRef} from 'react';
import {Routes, Route, useLocation, Outlet} from "react-router-dom";
import Home from './Home';
import Services from './Services';
import Posts from './Posts';
import Post from './Post';
import About from './About';
import Policy from './Policy';
import Opps from './Opps';
import Header from './Header';
import Footer from './Footer';
import './App.scss';

import {version} from '../package.json';

export default function App() {
    const [appName] = useState('A Tech Solution');
	const [page, setPage]= useState('/');

	const location = useLocation();
	const mainRef = useRef();
	
	useEffect(() => {
		setPage(location.pathname);

		if (page === location.pathname) window.scrollTo({top: 0});
	}, [location]);

    return (
        <>
            <div data-page={page} className="app">

                <Header name={appName} page={page} />

                <main id="main" className="app-main" role="main" ref={mainRef}>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="services" element={<Services />} />
                        <Route exact path="posts" element={<Posts />} />
						<Route exact path="posts/:id/:slug" element={<Post />} />
                        <Route exact path="about" element={<About />} />
                        <Route exact path="policy" element={<Policy />} />
						<Route path="*" element={<Opps />} />
                    </Routes>
                </main>

                <Footer version={version} />

            </div>

            <Outlet />
        </>
    );
}
