import React from 'react';
import { Link } from 'react-router-dom';

export default function Article(props) {
    const {id, slug, isExternal, publisher, domain, url, imageUrl, title, postDate, excerpt} = props;

    function handleRemoveHtmlTags(string, tags) {
        if (tags.length > 0) {
            for (let i = 0; i < tags.length; i++) {
                string = string.replace(tags[i], '');
            }
        }

        return string;
    }

    function handleDateParse(date) {
        const dateArray = date.split('');
		const yyyy = `${dateArray[0]}${dateArray[1]}${dateArray[2]}${dateArray[3]}`;
		const mm = `${dateArray[4]}${dateArray[5]}`;
		const dd = `${dateArray[6]}${dateArray[7]}`;

        return new Date(`${mm}-${dd}-${yyyy}`).toLocaleDateString(undefined)
    }

    return (
        <article className="article">
            {imageUrl && imageUrl.length > 0 && (
                <a className="article-img" href={url} target="_blank" rel="noreferrer">
                    <img src={imageUrl} alt={title} />
                </a>
            )}
            
            <div className="article-body">

                <h3 className="article-title">
                    {isExternal ? (
                        <a href={url} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: title}} />
                    ) : (
                        <Link to={`${id}/${slug}`} state={{postID: id}} dangerouslySetInnerHTML={{__html: title}} />
                    )}
                    
                </h3>

                <p className="article-timestamp">
					Posted by {isExternal ? <a className="link" href={domain} target="_blank" rel="noreferrer">{publisher}</a> : publisher} on {isExternal ? handleDateParse(postDate) : new Date(postDate).toLocaleDateString()}
				</p>

                {excerpt && excerpt.length > 0 && <p className="article-info" dangerouslySetInnerHTML={{__html: handleRemoveHtmlTags(excerpt, ['<p>', '</p>'])}}></p>}
                
                {isExternal ? (
                    <a className="link" href={url} target="_blank" rel="noreferrer"><span>Read more</span> &#8594;</a>
                ) : (
                    <Link className="link" to={`${id}/${slug}`} state={{postID: id}}><span>Read more</span> &#8594;</Link>
                )}
            </div>
        </article>
    );
}
