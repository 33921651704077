import React, { useState, useEffect } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import useFetchData from './useFetchData';

import './www-player.css';

export default function Post() {
	const { handle } = useParams();
	const location = useLocation();
	const { pathname } = location;
	const postID = location.state ? location.state.postID : Number(pathname.split('/')[2]);
	const { data, loading, error } = useFetchData(`posts/${postID}`);

	const [post, setPost] = useState(null);
	const [title, setTitle] = useState(null);
	const [author, setAuthor] = useState(null);
	const [date, setDate] = useState(null);
	const [content, setContent] = useState(null);

	useEffect(() => {
		if (data) {
			setPost(data);
			setTitle(data.title.rendered);
			setAuthor(data.x_author);
			setDate(new Date(data.date).toDateString());
			setContent(data.content.rendered);
		}
	}, [handle, data]);

	return (
		<>
			{error && <h2>Opps!</h2>}

			{post && (
				<section className="wrapper">
					<div className="container post" data-post-id={postID}>
						<Link className="link" to="/posts">← Back</Link>
						<h2 className="h1 post-title text-center text-uppercase" dangerouslySetInnerHTML={{__html: title}} />
						<p className="post-info text-center" dangerouslySetInnerHTML={{__html: `${author} on ${date}`}} />
						<div className="container post-content" dangerouslySetInnerHTML={{ __html: content}} />
					</div>
				</section>
			)}
		</>
	)
}
