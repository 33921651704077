import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import useFetchData from './useFetchData';

export default function Footer(props) {
	const {version} = props;
    const {data, loading, error} = useFetchData('posts');
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        if (data && data.length > 0) {
            setArticles(data);
        }
    }, [data]);

    return (
        <>
            <footer id="footer" className="app-footer">
                <div className="app-footer-body wrapper">
                    <div className="container">
                        <div className="footer-columns">
                            <div className="footer-column footer-column-bio">
								<div className="footer-columns">
									<div className="footer-column">
										<Link className="footer-logo" to="/">
                                            <i className="footer-logo-pulse"></i>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 382"><path fillRule="evenodd" clipRule="evenodd" d="M279.919 90.557c6.843 4.933 6.673 15.249-.194 20.147l-122.636 87.703c-4.309 3.073-10.143 3.001-14.428-.106l-36.212-25.875c-6.773-4.914-6.782-15.007-.017-19.932l54.146-37.7c1.132-.824 2.132-1.828 2.706-3.106 1.832-4.082 4.468-14.202-8.313-23.492-13.559-9.857-28.285-7.042-33.088-5.731-1.086.296-2.073.831-2.983 1.493L58.226 127.91a12.322 12.322 0 01-14.474.009l-24.086-17.238c-6.763-4.904-6.72-15.011.018-19.949L142.646 2.795c4.306-3.156 10.17-3.165 14.501-.043l122.772 87.805zm-94.011-3.627c15.686 0 28.403-9.357 28.403-20.9 0-11.542-12.717-20.9-28.403-20.9-15.687 0-28.404 9.358-28.404 20.9 0 11.543 12.717 20.9 28.404 20.9z" /><path d="M20.49 125.9c-8.146-5.905-19.64-.159-19.639 9.902v31.187c0 3.944 1.888 7.649 5.078 9.966l34.276 24.522c3.19 2.318 5.079 6.38 5.079 10.324v103.966a12.32 12.32 0 004.87 9.812l24.86 17.804c8.145 5.904 19.55.085 19.55-9.975v-87.092c0-2.517 2.854-3.971 4.89-2.492l24.833 17.785c8.144 5.917 19.561.099 19.561-9.967v-31.189a12.32 12.32 0 00-5.088-9.974L20.49 125.9zM279.845 125.551c8.145-5.905 19.496-.186 19.496 9.875v31.058c0 3.944-1.747 7.818-4.937 10.136l-86.614 61.94a6.09 6.09 0 00-2.511 4.929c0 4.978 5.574 7.857 9.601 4.931l64.899-46.39c8.144-5.917 19.562-.1 19.562 9.967v71.757c0 3.948-1.899 7.643-5.095 9.961L175.254 378.81c-8.145 5.905-18.915.766-18.915-9.294v-32.032c0-3.943 1.978-7.549 5.168-9.867l86.022-61.516c1.577-1.146 2.533-2.957 2.533-4.907 0-4.975-5.644-7.851-9.669-4.926l-65.3 46.675c-8.144 5.917-19.086.49-19.086-9.576v-72.909a12.32 12.32 0 015.088-9.974l118.75-84.933z" /></svg>
										</Link>
									</div>
									<div id="footer-cta" className="footer-column">
										<h4 className="footer-subtitle">Get&nbsp;in&nbsp;touch</h4>
										<ul className="contact-list contact-divider">
											<li className="contact-item">
												<span className="contact-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"/></svg></span>
												<a className="footer-link" href="mailto:hello@atechsolution.co">hello@atechsolution.co</a>
											</li>
											<li className="contact-item">
												<span className="contact-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15 12h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3zm4 0h2a9 9 0 0 0-9-9v2c3.87 0 7 3.13 7 7zm1 3.5c-1.25 0-2.45-.2-3.57-.57-.1-.03-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2a15.045 15.045 0 0 1-6.59-6.59l2.2-2.21a.96.96 0 0 0 .25-1A11.36 11.36 0 0 1 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM5.03 5h1.5c.07.88.22 1.75.45 2.58l-1.2 1.21c-.4-1.21-.66-2.47-.75-3.79zM19 18.97c-1.32-.09-2.6-.35-3.8-.76l1.2-1.2c.85.24 1.72.39 2.6.45v1.51z"/></svg></span>
												<a className="footer-link" href="tel:4707905725">470.790.5725</a>
											</li>
											<li className="contact-item">
												<span className="contact-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"/><circle cx="12" cy="9" r="2.5"/></svg></span>
												<address>
													3276 Buford Dr.<br />
													Suite 104, #390<br />
													Buford, GA 30519
												</address>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="footer-column footer-column-social">
								<h4 className="footer-subtitle">Follow</h4>
								<ul className="social-list">
                                    <li className="social-item"><a className="social-link" href="https://twitter.com/atechsolutionco" target="_blank" rel="noreferrer"><svg viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.1245 6.6734C29.144 6.9555 29.144 7.23761 29.144 7.52231C29.144 16.1973 22.5399 26.2023 10.464 26.2023V26.1971C6.89672 26.2023 3.40356 25.1805 0.400513 23.2539C0.919221 23.3163 1.44053 23.3475 1.96314 23.3488C4.91938 23.3514 7.79113 22.3594 10.1169 20.5329C7.30752 20.4796 4.84398 18.6479 3.98337 15.9737C4.96748 16.1635 5.9815 16.1245 6.94742 15.8606C3.88457 15.2418 1.68103 12.5508 1.68103 9.42554C1.68103 9.39694 1.68103 9.36964 1.68103 9.34234C2.59365 9.85065 3.61546 10.1328 4.66068 10.164C1.77593 8.23602 0.88672 4.39836 2.62875 1.39792C5.962 5.49948 10.88 7.99292 16.1594 8.25682C15.6303 5.97659 16.3531 3.58715 18.0587 1.98423C20.7029 -0.501413 24.8617 -0.374011 27.3473 2.26893C28.8177 1.97903 30.2269 1.43952 31.5165 0.675105C31.0264 2.19483 30.0007 3.48575 28.6305 4.30606C29.9318 4.15266 31.2032 3.80425 32.4005 3.27255C31.5191 4.59337 30.4089 5.74388 29.1245 6.6734Z" /></svg></a></li>
                                    <li className="social-item"><a className="social-link" href="https://www.facebook.com/atechsolutionco" target="_blank" rel="noreferrer"><svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M32.2672 16.0003C32.2672 7.16368 25.1038 0.000244141 16.2672 0.000244141C7.43065 0.000244141 0.267212 7.16368 0.267212 16.0003C0.267212 23.9863 6.11817 30.6056 13.7672 31.8059V20.6252H9.70472V16.0003H13.7672V12.4753C13.7672 8.46524 16.1559 6.25025 19.8106 6.25025C21.5612 6.25025 23.3922 6.56275 23.3922 6.56275V10.5002H21.3747C19.3871 10.5002 18.7672 11.7336 18.7672 12.9989V16.0003H23.2047L22.4953 20.6252H18.7672V31.8059C26.4163 30.6056 32.2672 23.9863 32.2672 16.0003Z" /></svg></a></li>
                                    <li className="social-item"><a className="social-link" href="https://www.linkedin.com/company/atechsolutionco" target="_blank" rel="noreferrer"><svg viewBox="0 0 34 34"><path d="M34 2.5v29a2.5 2.5 0 0 1-2.5 2.5h-29A2.5 2.5 0 0 1 0 31.5v-29A2.5 2.5 0 0 1 2.5 0h29A2.5 2.5 0 0 1 34 2.5ZM10 13H5v16h5Zm.45-5.5a2.88 2.88 0 0 0-2.86-2.9H7.5a2.9 2.9 0 0 0 0 5.8 2.88 2.88 0 0 0 2.95-2.81ZM29 19.28c0-4.81-3.06-6.68-6.1-6.68a5.7 5.7 0 0 0-5.06 2.58h-.14V13H13v16h5v-8.51a3.32 3.32 0 0 1 3-3.58h.19c1.59 0 2.77 1 2.77 3.52V29h5Z" /></svg></a></li>
                                </ul>
                            </div>
                            <div className="footer-column footer-column-company">
                                <h4 className="footer-subtitle">Company</h4>
                                <ul>
                                    <li>
                                        <Link className="footer-link" to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link className="footer-link" to="/services">Services</Link>
                                    </li>
                                    <li>
                                        <Link className="footer-link" to="/posts">News&nbsp;&&nbsp;Articles</Link>
                                    </li>
                                    <li>
                                        <Link className="footer-link" to="/about">About</Link>
                                    </li>
                                    <li>
                                        <Link className="footer-link" to="/policy">Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-column footer-column-posts">
                                <h4 className="footer-subtitle">Latest&nbsp;News&nbsp;&&nbsp;Articles</h4>
                                <ul className="bars">
                                    {articles.slice(0, 3).map(article => {
										return (
											<li key={article.id} className="footer-divider">
												{article.acf.is_external_post ? (
													<a className="footer-link" href={article.acf.article_link} target="blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: article.title.rendered}} />
												) : (
													<Link
														className="footer-link"
														to={`posts/${article.id}/${article.slug}`}
														state={{postID: article.id}}
														dangerouslySetInnerHTML={{__html: article.title.rendered}} />
												)}
											</li>
										)
									})}
                                </ul>
                                <div className="mt-2">
                                    <Link className="footer-link" to="/posts">See all News & Articles &#8594;</Link>
                                </div>
                            </div>
                        </div>
                        <small className="footer-legal">Copyright &copy; {new Date().getFullYear()} A Tech Solution. All rights reserved. (v{version})</small>
                    </div>
                </div>
            </footer>   
        </>
    )
}
